
/**
 * @name: 小程序管理-首页兑换专区商品管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-首页兑换专区商品管理
 * @update: 2023-12-07 16:39
 */
import {Vue, Component, Watch} from "vue-property-decorator"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {IAppletExchangeShop, IAppletExchangeShopParams} from "@/apis/applet/exchangeShop/types";
import {
  productZoneCreateApi,
  productZoneQueryAddIdsApi,
  productZoneQueryApi,
  productZoneRemoveApi,
  productZoneShowInHomeApi
} from "@/apis/applet/exchangeShop";
import {ISortProduct} from "@/apis/card/type/types";
import {getSortProductListApi} from "@/apis/card/type";
import {IProductList} from "@/apis/product/list/types";
import {deepCopy} from "@/utils/common";

@Component({})
export default class appletExchangeShop extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IAppletExchangeShop[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IAppletExchangeShopParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 150,
    column: [
      {
        label: "商品",
        prop: "productNameOrNum",
        search: true,
        hide: true,
        placeholder: "输入编号/名称模糊搜索"
      },
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center"
      },
      {
        label: "商品编号",
        prop: "productId",
        align: "center",
        width: 180
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品图片",
        prop: "productPic",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl
      },
      {
        label: "排序",
        prop: "productSort",
        align: "center"
      },
      {
        label: "首页展示",
        prop: "isHomeShow",
        align: "center",
        slot: true,
        search: true,
        type: "select",
        dicData: [
          {
            label: "展示",
            value: 1
          },
          {
            label: "不展示",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: "productSortId",
        align: "center",
        search: true,
        hide: true,
        type: "select",
        dicUrl: "/api/card/productSort/dropDown",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'productSortId'}),
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品售价(元)",
        prop: "productPrice",
        align: "center"
      },
      {
        label: "商品状态",
        prop: "productStatus",
        align: "center",
        search: true,
        type: "select",
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          }
        ]
      },
    ]
  }

  selectRows: IAppletExchangeShop[] = []

  // 添加弹窗
  productScopeDialog = false
  // 商品、分类数据
  productSortList: ISortProduct[] = []
  // 选择的商品id
  selectProductIds: string[] = []
  // 是否全选
  checkedAll = false

  selectProductArr: IProductList[] = []
  // 缓存的商品选择ids
  tempSelectProductIds: string[] = []

  @Watch('selectProductIds')
  onSelectProductChange(newVal: string[]) {
    let allProductLength = 0
    for (let i = 0; i < this.productSortList.length; i++) {
      allProductLength += (this.productSortList[i]['productVoList'] ? this.productSortList[i]['productVoList'].length : 0)
    }
    if (allProductLength == newVal.length) {
      this.checkedAll = true
    } else {
      this.checkedAll = false
    }
  }

  dealProductList (arr: ISortProduct[]) {
    const result: ISortProduct[] = []
    if (!arr || !arr.length) {
      return result
    }
    for (let i = 0; i < arr.length; i++) {
      // @ts-ignore
      arr[i]['id'] = arr[i]['productId'] || arr[i]['productSortId']
      // @ts-ignore
      arr[i]['name'] = arr[i]['productName'] || arr[i]['sortName']

      const obj: ISortProduct = deepCopy(arr[i])

      if (arr[i]['productVoList'] && arr[i]['productVoList'].length) {
        // @ts-ignore
        obj.productVoList = this.dealProductList(arr[i]['productVoList'])
      }
      // @ts-ignore
      if (!arr[i]['productId']) {
        result.push(obj)
        continue;
      }
      // @ts-ignore
      if (arr[i]['productId'] && !this.tempSelectProductIds.includes(arr[i]['productId'])) {
        result.push(obj)
      }
    }
    return result
  }

  getProductSortList () {
    return new Promise(resolve => {
      getSortProductListApi().then(e => {
        this.productSortList = this.dealProductList(e).filter(item => item.productVoList && item.productVoList.length)
        resolve(null)
      })
    })
  }

  getList () {
    this.tableLoading = true
    productZoneQueryApi(this.queryParam).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  statusChange (productZoneId: string, isHomeShow: number) {
    this.tableLoading = true
    productZoneShowInHomeApi({productZoneId, isHomeShow}).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  removeIds (arr: string[]) {
    if (!arr || !arr.length) {
      return this.$message.error("请选择需要移除的数据")
    }
    this.$confirm("是否确认移除？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      productZoneRemoveApi(arr).then(e => {
        if (e) {
          this.getList()
          this.$message.success("操作成功!")
        }
      })
    }).catch(()=>{})
  }

  batchRemove () {
    const ids = this.selectRows.map(item => item.productZoneId)
    this.removeIds(ids)
  }

  selectAll (status: boolean) {
    let ids = []
    if (status) {
      const arr = this.productSortList;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].productVoList && arr[i].productVoList.length) {
          for (let j = 0; j < arr[i].productVoList.length; j++) {
            let item = arr[i].productVoList[j]
            ids.push(item.id)
          }
        }
      }
    } else {
      ids = []
    }
    // @ts-ignore
    this.$refs.productScopeTreeRef.setCheckedKeys(ids, true)
  }

  productScopeTreeChange (data: any) {
    // @ts-ignore
    this.selectProductArr = this.$refs.productScopeTreeRef.getCheckedNodes(true)
    // @ts-ignore
    this.selectProductIds = this.$refs.productScopeTreeRef.getCheckedKeys(true)
  }

  handleProductScopeEnter (done: Function, loading: Function) {
    if (!this.selectProductIds || !this.selectProductIds.length) {
      loading()
      return this.$message.error("请选择需要添加的商品")
    }
    const data: Partial<IAppletExchangeShop>[] = this.selectProductArr.map(item => {
      return {
        productId: item.productId,
        productSortId: item.productSortId
      }
    })
    productZoneCreateApi(data).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  handleProductScopeClose () {
    this.selectProductIds = []
    this.selectProductArr = []
    this.tempSelectProductIds = []
  }

  openProductScope () {
    productZoneQueryAddIdsApi().then(async e => {
      if (e) {
        this.tempSelectProductIds = e.map(item => item.productId);
        await this.getProductSortList()
        if (!this.productSortList || !this.productSortList.length) {
          this.$message.warning("全部商品已添加，当前暂无可添加商品！")
        } else {
          this.productScopeDialog = true
        }
      }
    })
  }

  created () {
    this.getList()
  }
}
