/**
 * @name: 小程序管理-首页兑换专区商品管理接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-首页兑换专区商品管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IAppletExchangeShop, IAppletExchangeShopParams} from "@/apis/applet/exchangeShop/types";

/**
 * 分页查询
 * @param params
 */
export const productZoneQueryApi = (params: IAppletExchangeShopParams) => get<IPageRes<IAppletExchangeShop[]>>("/card/product/zone/query", params)
/**
 * 切换首页展示
 * @param data
 */
export const productZoneShowInHomeApi = (data: {productZoneId: string, isHomeShow: number}) => postJ("/card/product/zone/show-in-home", data)
/**
 * 批量删除
 * @param ids
 */
export const productZoneRemoveApi = (ids: string[]) => postJ("/card/product/zone/remove", ids)
/**
 * 已选择的专区商品
 */
export const productZoneQueryAddIdsApi = () => get<IAppletExchangeShop[]>("/card/product/zone/all")
/**
 * 创建
 * @param data
 */
export const productZoneCreateApi = (data: Partial<IAppletExchangeShop>[]) => postJ("/card/product/zone/create", data)
